<template>
  <div class="flex-1 flex-column">
    <div class="flex-row card-content">
      <SummaryBlock title="总-发电量" :value="total.value" :unit="total.unit" />
      <SummaryBlock title="尖-发电量" :value="summit.value" :unit="summit.unit" style="border-top: 4px solid #ff6d2c" />
      <SummaryBlock title="峰-发电量" :value="peak.value" :unit="peak.unit" style="border-top: 4px solid #ce90d1" />
      <SummaryBlock title="平-发电量" :value="plain.value" :unit="plain.unit" style="border-top: 4px solid #0db6d9" />
      <SummaryBlock title="谷-发电量" :value="valley.value" :unit="valley.unit" style="border-top: 4px solid #63bc7f" />
    </div>
    <ChartBase :option="option" class="flex-1 flex-column" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import dayjs from "dayjs";
import { kwhFormat, toFixed } from "@/utils/unitFormat";
import np from "@/utils/number";
import { isNil } from "lodash";

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: "hour",
  },
  data: {
    type: Object,
    default: {},
  },
});

const total = computed<{ value: string; unit: string }>(() => {
  console.log('props.data', props.data);
  if (isNil(props.data?.totalChargeGenerationT)) return { value: "", unit: "" };
  return kwhFormat(props.data.totalChargeGenerationT);
});
const summit = computed<{ value: string; unit: string }>(() => {
  if (isNil(props.data?.summitChargeGenerationT)) return { value: "", unit: "" };
  return kwhFormat(props.data.summitChargeGenerationT);
});
const peak = computed<{ value: string; unit: string }>(() => {
  if (isNil(props.data?.peakChargeGenerationT)) return { value: "", unit: "" };
  return kwhFormat(props.data.peakChargeGenerationT);
});
const plain = computed<{ value: string; unit: string }>(() => {
  if (isNil(props.data?.plainChargeGenerationT)) return { value: "", unit: "" };
  return kwhFormat(props.data.plainChargeGenerationT);
});
const valley = computed<{ value: string; unit: string }>(() => {
  if (isNil(props.data?.valleyChargeGenerationT)) return { value: "", unit: "" };
  return kwhFormat(props.data.valleyChargeGenerationT);
});

const times = computed(() => (props.data?.list || []).map((d: any) => d.time));
const summitValues = computed(() => (props.data?.list || []).map((d: any) => [d.time, d.summitChargeGeneration, d.totalChargeGeneration]));
const peakValues = computed(() => (props.data?.list || []).map((d: any) => [d.time, d.peakChargeGeneration, d.totalChargeGeneration]));
const plainValues = computed(() => (props.data?.list || []).map((d: any) => [d.time, d.plainChargeGeneration, d.totalChargeGeneration]));
const valleyValues = computed(() => (props.data?.list || []).map((d: any) => [d.time, d.valleyChargeGeneration, d.totalChargeGeneration]));
const totalValues = computed(() => (props.data?.list || []).map((d: any) => d.totalChargeGeneration));

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: props.data.length > 12,
  };
});

enum dateType {
  hour = "YYYY-MM-DD HH:mm",
  day = "YYYY-MM-DD",
  month = "YYYY年MM月",
}

const tooltipFormatter = (tooltipData: any) => {
  const date = dayjs(tooltipData[0].name);
  return `
  <div style="margin-bottom:8px">${date.format(dateType[props.mode as keyof typeof dateType])}</div>
  <div style="display:flex; align-items: center;">
    <span style="display:inline-block;margin-right:35px">总-发电量</span><span  style="flex:1;text-align:right">${toFixed(tooltipData[0].value[2])} kWh</span>
  </div>
  ${tooltipData?.map((item: any) => (
    `
      <div style="display:flex; align-items: center;">
        <span style="display:inline-block;margin-right:4px; width:8px;height:8px;border-radius: 50%;background: ${item.color}"></span>
        <span style="display:inline-block;margin-right:35px">${item.seriesName}</span><span style="flex:1;text-align:right">${toFixed(item.value[1])} kWh</span>
      </div>
    `
  )).join('')}
`;
};

function xAxisLabelFormatter(name: any) {
  const date = dayjs(name);
  if (props.mode === "hour") {
    return `${date.format("HH:mm")}\n${date.format("MM-DD")}`;
  }
  if (props.mode === "day") {
    return `${date.format("MM-DD")}`;
  }
  if (props.mode === "month") {
    return `${date.format("MM月")}\n${date.format("YY年")}`;
  }
}

const option = computed(() => ({
  grid: {
    right: 10,
    containLabel: true,
    bottom: 100,
  },
  legend: {
    right: 0,
    icon: "rect",
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 16,
    data: ["尖-发电量", "峰-发电量", "平-发电量", "谷-发电量"],
  },
  tooltip: {
    trigger: "axis",
    backgroundColor: 'rgba(0,0,0,0.8)',
    padding: [8, 12, 0, 12],
    axisPointer: {
      type: "shadow",
    },
    formatter: tooltipFormatter,
  },
  dataZoom: dataZoom.value,
  xAxis: {
    type: "category",
    data: times.value,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLabelFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "kWh",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      type: "bar",
      name: "谷-发电量",
      data: valleyValues.value,
      stack: "Ad",
      itemStyle: {
        color: "#63BC7F",
      },
      barMaxWidth: "24px",
      barMinWidth: "12px",
    },
    {
      type: "bar",
      name: "平-发电量",
      data: plainValues.value,
      stack: "Ad",
      itemStyle: {
        color: "#0DB6D9",
      },
    },
    {
      type: "bar",
      name: "峰-发电量",
      data: peakValues.value,
      stack: "Ad",
      itemStyle: {
        color: "#CE90D1",
      },
    },
    {
      type: "bar",
      name: "尖-发电量",
      data: summitValues.value,
      stack: "Ad",
      itemStyle: {
        color: "#FF6D2C",
      },
    }
  ],
}));
</script>

<style lang="scss" scoped>
.card-content {
  gap: 8px;
  margin-bottom: 16px;
  .summary-block {
    flex: 1;
  }
}
</style>
