<template>
  <div class="x-hand-dialog">
    <span @click="handleOpen">
      <slot name="hand" />
    </span>
    <mx-dialog
      v-if="visible"
      :lock-scroll="false"
      v-model="visible"
      :title="title"
      size="large"
      :top="top"
    >
      <slot />
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </mx-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

interface Props {
  title?: string;
  disabled?: boolean;
  width?: number | string;
  top: string;
}
const props = withDefaults(defineProps<Props>(), {
  title: "",
  disabled: false,
  top: "15vh",
});

const visible = ref(false);

const handleOpen = () => {
  if (props.disabled) return;
  visible.value = true;
};

defineExpose({
  visible,
});
</script>

<style lang="scss">
.el-button + .x-hand-dialog,
.x-hand-dialog + .el-button {
  margin-left: 8px;
}
</style>
