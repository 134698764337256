import request from "@/utils/request";
import { dateRangeDec } from "@/utils/date";

export const fetchDynamic = (query: any, fetchUrl: string, names: string[]) => {
  const params = {} as any;
  let primaryIds = [];
  let primaryKey = "";
  if ("stationIds" in query) {
    primaryKey = "stationId";
    primaryIds = query.stationIds;
  }
  if ("deviceIds" in query) {
    primaryKey = "deviceId";
    primaryIds = query.deviceIds;
  }
  if ("dateRange" in query) {
    const [startTime, endTime] = dateRangeDec(query.dateRange);
    params.startTime = startTime;
    params.endTime = endTime;
  }
  if ("dateType" in query) {
    params.queryType = query.dateType;
  }

  const fetches = [];
  for (let id of primaryIds) {
    const baseParams = {} as any;
    baseParams[primaryKey] = id;

    fetches.push(
      request.get(fetchUrl, { params: { ...baseParams, ...params } }),
    );
  }
  return Promise.all(fetches).then((result: any) => buildResult(result, names));
};

function buildResult(results: any[], names: string[]) {
  let times = [];

  const series = [] as any[];
  for (let idx in results) {
    const result: any = results[idx]?.list || results[idx];
    if (result instanceof Array) {
      if (idx === "0") {
        times = result.map((d: any) => d.time);
      }
      const values = result.map((d: any) => (d.value || d.totalChargeGeneration));
      series.push({ name: names[idx], data: values });
      continue;
    }
    if ("ab" in result && "bc" in result && "ca" in result) {
      if (idx === "0") {
        times = result.ab.map((d: any) => d.time);
      }

      series.push({
        name: `${names[idx]}-AB`,
        data: result.ab.map((d: any) => (d.value || d.totalChargeGeneration)),
      });
      series.push({
        name: `${names[idx]}-BC`,
        data: result.bc.map((d: any) => (d.value || d.totalChargeGeneration)),
      });
      series.push({
        name: `${names[idx]}-CA`,
        data: result.ca.map((d: any) => (d.value || d.totalChargeGeneration)),
      });
    }
    if ("a" in result && "b" in result && "c" in result) {
      if (idx === "0") {
        times = result.a.map((d: any) => d.time);
      }

      series.push({
        name: `${names[idx]}-A`,
        data: result.a.map((d: any) => (d.value || d.totalChargeGeneration)),
      });
      series.push({
        name: `${names[idx]}-B`,
        data: result.b.map((d: any) => (d.value || d.totalChargeGeneration)),
      });
      series.push({
        name: `${names[idx]}-C`,
        data: result.c.map((d: any) => (d.value || d.totalChargeGeneration)),
      });
    }
  }

  return {
    times,
    series,
  };
}
