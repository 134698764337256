<template>
  <div class="flex-1 flex-column" style="position: relative">
    <ChartBase :option="option" :update-options="{ notMerge: true }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import merge from "merge";
//柱状图和折线结合
const props = defineProps({
  data: {
    type: Array,
    default: () => ["#0050B3", "#00ADFF"],
  },
  unit: {
    type: Array,
    default: () => [],
  },
  color: {
    type: Array,
    default: () => [],
  },
});

const serieCfgs = (index: number) => {
  if (index === 0) {
    return {
      data: [],
      type: "bar",
      barWidth: 30,
      yAxisIndex: index,
    };
  } else {
    return {
      data: [],
      type: "line",
      yAxisIndex: index,
    };
  }
};

const times = computed(() => {
  if (props.data.length === 0) return [];
  return props.data[0].data.length > props.data[1].data.length
    ? props.data[0].data.map((d: any) => d.time)
    : props.data[1].data.map((d: any) => d.time);
});
const series = computed(() => {
  return props.data.map((d: any) => {
    return {
      ...d,
      name: d.name,
      data: d.data.map((d: any) => d.value),
    };
  });
});
const option = computed(() => ({
  grid: {
    x: 40,
    y: 60,
    right: 70,
    bottom: 40,
    containLabel: true,
  },
  legend: {
    show: false,
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    confine: true,
  },
  color: props.color,
  xAxis: {
    type: "category",
    data: times.value,
    axisLabel: {
      lineHeight: 20,
      align: "center",
      color: "#FFFFFF",
    },
  },
  yAxis: [
    {
      type: "value",
      name: props.unit.length ? props.unit[0] : "",
      nameTextStyle: {
        align: "left",
      },
      axisLabel: {
        lineHeight: 20,
        color: "#FFFFFF",
      },
      splitLine: {
        lineStyle: {
          color: "rgba(255, 255, 255, 0.30)",
        },
      },
    },
    {
      type: "value",
      name: props.unit.length > 1 ? props.unit[1] : "",
      nameTextStyle: {
        align: "right",
      },
      axisLabel: {
        lineHeight: 20,
        color: "#FFFFFF",
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: "rgba(255, 255, 255, 0.30)",
        },
      },
    },
  ],

  series: series.value.map((d, index) => merge({}, serieCfgs(index), d)),
}));
</script>
