<template>
  <mx-select v-model="currentValue" placeholder="请选择" style="width: 158px">
    <mx-option
      v-for="item in options"
      :key="item.code"
      :label="item.name"
      :value="item.code"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from "vue";
import { list as fetchList } from "@/api/serialString";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps({
  stationId: {
    type: [Number, null] as PropType<number | null>,
    default: null,
    require: true,
  },
  areaId: {
    type: [Number, null] as PropType<number | null>,
    default: null,
    require: true,
  },
  combinerBoxId: {
    type: [Number, null] as PropType<number | null>,
    default: null,
    require: true,
  },
  inverterId: {
    type: [Number, null] as PropType<number | null>,
    default: null,
    require: true,
  },
  modelValue: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue", "itemsChange"]);
const currentValue = computed<string | null>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

watch(
  () => props.inverterId,
  () => {
    if (!props.inverterId) {
      emit("update:modelValue", null);
      return;
    }
    if (!props.stationId || !props.areaId || !props.combinerBoxId) {
      return;
    }
    fetchList(
      props.stationId,
      props.areaId,
      props.combinerBoxId,
      props.inverterId,
    ).then(list => {
      options.value = list.filter(d => d?.code);
      if (
        route.query?.stringCode &&
        list.filter((item: any) => {
          return item.name == route.query?.stringCode;
        }).length
      ) {
        const itemData = list.filter((item: any) => {
          return item.name == route.query?.stringCode;
        });
        emit("update:modelValue", itemData[0].code);
        return;
      }
      emit(
        "update:modelValue",
        options.value.length > 0 ? options.value[0].code : null,
      );
    });
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      let device = props.modelValue
        ? options.value.filter(d => props.modelValue == d.code)[0]
        : [];
      emit("itemsChange", device);
    });
  },
);
</script>
