type Callback = () => void;

const store: Record<string, ReturnType<typeof setTimeout>> = {};

const debounce = (name: string, callback: Callback, wait = 100): void => {
  if (store[name]) {
    clearTimeout(store[name]);
  }

  store[name] = setTimeout(() => {
    callback();
    delete store[name];
  }, wait);
};

export default debounce;
