<template>
  <div class="flex-1 flex-column" style="position: relative">
    <ChartBase :option="option" :update-options="{ notMerge: true }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import merge from "merge";
import { getDateBetween, getYearBetween } from "@/utils/date";

const props = defineProps({
  data: {
    type: Array,
    default: () => ["rgba(255,255,255,0)", "#CE90D1", "#0DB6D9", "#63BC7F"],
  },
  unit: {
    type: String,
    default: "",
  },
  color: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: "month",
  },
  tooltip: {
    type: Object,
    default: () => ({}),
  },
  yAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  xAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  isLegend: {
    type: Boolean,
    default: false,
  },
});

const serieCfgs = {
  data: [],
  type: "scatter",
};

const times = computed(() => {
  if (props.data.length === 0) return [];
  const startTime = props.data[0].data[0].time;

  const endTime =
    props.data[0].data.length > 1
      ? props.data[0].data[props.data[0].data.length - 1].time
      : props.data[0].data[0].time;

  return props.type === "month"
    ? getDateBetween(startTime, endTime)
    : getYearBetween(startTime, endTime);
});
const series = computed(() => {
  return props.data.map((d: any) => {
    return {
      ...d,
      name: d.name,
      data: d.data.map((d: any) => {
        return {
          value: d.value,
          ...d,
        };
      }),
    };
  });
});
const option = computed(() => ({
  grid: {
    x: 40,
    y: 60,
    right: props.xAxisOptions ? 70 : 10,
    bottom: 40,
    containLabel: true,
  },
  legend: {
    show: props.isLegend,
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    confine: true,
    ...props.tooltip,
  },
  color: props.color,
  xAxis: {
    type: "category",
    data: times.value,
    axisLabel: {
      lineHeight: 20,
      align: "center",
      color: "#FFFFFF",
    },
    ...props.xAxisOptions,
  },
  yAxis: {
    type: "value",
    name: props.unit,
    axisLabel: {
      lineHeight: 20,
      color: "#FFFFFF",
    },
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
    ...props.yAxisOptions,
  },
  series: series.value.map(d => merge({}, serieCfgs, d)),
}));
</script>
