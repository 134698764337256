<template>
  <div class="flex-1 flex-column" style="position: relative">
    <ChartBase :option="option" :update-options="{ notMerge: true }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import merge from "merge";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  unit: {
    type: String,
    default: "",
  },
  color: {
    type: Array as PropType<string[]>,
    default: () => ["#CE90D1", "#0DB6D9", "#63BC7F"],
  },
  yAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  xAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  isLegend: {
    type: Boolean,
    default: false,
  },
  barWidth: {
    type: Number,
    default: 30,
  },
  grid: {
    type: Object,
    default: () => ({}),
  },
  tooltip: {
    type: Object,
    default: () => ({}),
  },
  dataZoom: {
    type: Array,
    default: () => [],
  },
});

const serieCfgs = {
  data: [],
  type: "bar",
  barWidth: props.barWidth || 30,
};

const times = computed(() => {
  if (props.data.length === 0) return [];
  // @ts-ignore
  return props.data[0].data.map((d: any) => d.time);
});
const series = computed(() => {
  return props.data.map((d: any) => {
    return {
      ...d,
      name: d.name,
      data: d.data.map((d: any) => {
        return { ...d };
      }),
    };
  });
});
const option = computed(() => ({
  grid: {
    x: 40,
    y: 60,
    right: props.xAxisOptions ? 100 : 10,
    bottom: 40,
    containLabel: true,
    ...props.grid,
  },
  legend: {
    show: props.isLegend,
    right: 0,
    itemWidth: 16,
    itemHeight: 7,
  },
  tooltip: {
    trigger: "axis",
    confine: true,
    appendToBody: true,
    ...props.tooltip,
  },
  dataZoom: props.dataZoom,
  color: props.color,
  yAxis: {
    type: "category",
    data: times.value,
    axisLabel: {
      lineHeight: 20,
      align: "center",
      color: "#FFFFFF",
    },
    ...props.yAxisOptions,
  },
  xAxis: {
    type: "value",
    name: props.unit,
    axisLabel: {
      lineHeight: 20,
      color: "#FFFFFF",
    },
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
    ...props.xAxisOptions,
  },
  series: series.value.map(d => merge({}, serieCfgs, d)),
}));
</script>
