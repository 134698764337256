<template>
  <div
    v-loading="loading"
    class="flex-1 flex-column"
    style="position: relative; height: 100%"
  >
    <div class="refresh-btn" @click="handleRefresh">
      <svg
        t="1658207887917"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2717"
        width="16"
        height="16"
      >
        <path
          d="M512 919.552c-224.768 0-407.552-182.784-407.552-407.552 0-8.704 0.512-17.408 1.024-26.112l71.68 4.608c-0.512 7.168-0.512 14.336-0.512 21.504 0 185.344 150.528 335.872 335.872 335.872 86.528 0 168.448-32.768 230.912-92.16l49.152 52.224c-76.288 72.192-175.616 111.616-280.576 111.616zM919.552 512h-71.68c0-11.776-0.512-23.552-2.048-35.328-17.92-171.52-161.28-300.544-334.336-300.544-67.584 0-132.096 19.968-187.904 57.344L284.16 174.08C351.232 128.512 430.08 104.448 512 104.448c209.408 0 384 156.672 405.504 365.056 1.536 13.824 2.048 28.16 2.048 42.496z"
          p-id="2718"
          fill="#b2b2b2"
        ></path>
        <path
          d="M140.288 290.816L28.16 491.52c-3.072 5.12 1.024 11.776 6.656 11.776h223.744c6.144 0 9.728-6.144 6.656-11.776L153.6 290.816c-3.072-5.632-10.752-5.632-13.312 0zM870.4 675.84l-112.128-200.704c-3.072-5.12 1.024-11.776 6.656-11.776h223.744c6.144 0 9.728 6.144 6.656 11.776L883.712 675.84c-2.56 5.12-10.24 5.12-13.312 0z"
          p-id="2719"
          fill="#b2b2b2"
        ></path>
        <path
          d="M270.336 202.24a35.84 35.84 0 1 0 71.68 0 35.84 35.84 0 1 0-71.68 0Z"
          p-id="2720"
          fill="#b2b2b2"
        ></path>
        <path
          d="M728.576 784.896a35.84 35.84 0 1 0 71.68 0 35.84 35.84 0 1 0-71.68 0Z"
          p-id="2721"
          fill="#b2b2b2"
        ></path>
      </svg>
    </div>
    <ChartBase
      :option="option"
      :update-options="{ notMerge: true }"
      class="flex-1 flex-column"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import debounce from "@/utils/debounce";
import { fetchDynamic } from "@/api/compareAdaper";
import dayjs from "dayjs";
import merge from "merge";
import { hoursFormat } from "@/utils/unitFormat";
import numberParse from "@/utils/number";

const props = defineProps({
  type: {
    type: String,
    defualt: "",
    required: true,
  },
  query: {
    type: Object,
    default: {},
    required: true,
  },
  url: {
    type: String,
    default: "",
    required: true,
  },
  names: {
    type: Array,
    defualt: [],
    required: true,
  },
  timeMode: {
    type: String,
    default: "hour",
  },
});

const loading = ref(false);
const times = ref([]);
const series = ref([]);
// const instance = getCurrentInstance()

const loadedData = () => {
  debounce("chart-compare-lines", () => {
    const params = merge(props.query);

    if (!params.dateType) params.dateType = "day";
    if (!params.deviceIds && !params.stationIds) return;
    if (
      params.deviceIds &&
      params.deviceIds.filter((d: any) => d !== null).length === 0
    ) {
      times.value = [];
      series.value = [];
      return;
    }
    if (
      params.stationIds &&
      params.stationIds.filter((d: any) => d !== null).length === 0
    ) {
      times.value = [];
      series.value = [];
      return;
    }
    // @ts-ignore
    if (!params.dateRange || params.dateRange.length !== 2) return;

    loading.value = true;
    fetchDynamic(params, props.url, props.names as any).then(result => {
      times.value = result.times as any; //[...result.times, ...result.times, ...result.times, ...result.times, ...result.times, ...result.times]
      series.value = result.series as any; //[...result.series, ...result.series, ...result.series, ...result.series, ...result.series, ...result.series]
      loading.value = false;
      // instance?.ctx.$forceUpdate()
    });
  });
};

watch(
  () => props.query,
  () => {
    loadedData();
  },
  { immediate: true, deep: true },
);

type TypeMaps = {
  [key: string]: {
    unit: string;
    valueRender: Function | null;
  };
};

const typedMaps: TypeMaps = {
  outletsPower: {
    unit: "kWh",
    valueRender: null,
  },
  power: {
    unit: "kWh",
    valueRender: null,
  },
  kw: {
    unit: "kW",
    valueRender: null,
  },
  co2: {
    unit: "t",
    valueRender: null,
  },
  hours: {
    unit: "",
    valueRender: hoursFormat,
  },
  efficiency: {
    unit: "%",
    valueRender: null,
  },
  voltage: {
    unit: "V",
    valueRender: null,
  },
  voltage3: {
    unit: "V",
    valueRender: null,
  },
  electricity: {
    unit: "A",
    valueRender: null,
  },
  temperature: {
    unit: "°C",
    valueRender: null,
  },
  kwfactor: {
    unit: "",
    valueRender: null,
  },
};
// @ts-ignore
const typeCfg = computed<any>(() => typedMaps[props.type] || {});
const tooltipFormatter = (series: Array<any>) => {
  if (series.length === 0) return "";
  // 仅取第一个作为time
  let time = dayjs(series[0].name).format("YYYY-MM-DD HH:mm");
  if (props.timeMode === "hour") {
  } else if (props.timeMode === "day") {
    time = dayjs(series[0].name).format("YYYY-MM-DD");
  } else if (props.timeMode === "month") {
    time = dayjs(series[0].name).format("YYYY年MM月");
  }
  let values = series
    .map(d => {
      if (null === d.value || undefined === d.value) {
        return "";
      }
      const value = typeCfg.value.valueRender
        ? typeCfg.value.valueRender(d.value)
        : numberParse(d.value);
      return `
      <div class="value-item">
        <label>${d.seriesName}</label><label style="color:${d.color}">${value} ${typeCfg.value.unit}</label>
      </div>
    `;
    })
    .join("");
  if (values === "") return "";
  if (series.length > 9) {
    values = `<div class="grid-column2">${values}</div>`;
  }
  return `
    <div class="chart-tooltip">
      <div class="time">${time}</div>
      ${values}
    </div>
  `;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  if (props.timeMode === "hour") {
    const shortDate = date.format("MM-DD");
    const shortTime = date.format("HH:mm");

    return `${shortTime}\n${shortDate}`;
  } else if (props.timeMode === "day") {
    const shortTime = date.format("MM-DD");

    return `${shortTime}`;
  } else if (props.timeMode === "month") {
    const shortDate = date.format("YY年");
    const shortTime = date.format("MM月");

    return `${shortTime}\n${shortDate}`;
  }
};

const serieCfgs = {
  data: [],
  type: "line",
  connectNulls: true,
  smooth: true,
  symbol: "none",
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: times.value.length > 3,
    filterMode: "none",
  };
});

const option = computed(() => ({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    containLabel: true,
    bottom: 100,
  },
  legend: {
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter,
    confine: true,
  },
  dataZoom: dataZoom.value,
  xAxis: {
    type: "category",
    data: times.value,
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: typeCfg.value.unit,
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: series.value.map(d => merge({}, serieCfgs, d)),
}));

const handleRefresh = () => {
  loadedData();
};
</script>

<style lang="scss" scoped>
.refresh-btn {
  position: absolute;
  left: 40px;
  top: 0px;
  padding: 5px 8px;
  cursor: pointer;
  z-index: 999;
  &:active {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
