export default (value: any, dec = 2) => {
  if (value === undefined || value === null) return null;
  if (typeof value === "string") value = Number(value);
  return Number(value.toFixed(dec));
};

export const toNumber = (value: number | string) => {
  if (!value) return 0;
  if (typeof value === "string") {
    if (value.trim() === "") return 0;
    value = Number(value);
  }
  if (value % 1 === 0) return value;
  return value.toFixed(2);
};

export const parseNumber = (value: string) =>
  parseFloat(value || "0").toFixed(2);
